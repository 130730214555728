import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { DataSet, Timeline, timeline as TimelineUtils } from "vis-timeline/standalone";

export class VesselHistoryProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
        this.dataSet = new DataSet();
    }

    getAllAsGetAllAsGeoJsonByProject(start, end, projectId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/VesselHistory/GetAllAsGeoJsonByProject`,
                type: 'GET',
                data: {
                    start: start,
                    end: end,
                    projectId: projectId
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    createTimelineItems(data) {
        let minTime = DateTime.utc().toMillis();
        let maxTime = DateTime.utc().toMillis();

        if (data.properties) {
            minTime = data.properties.minTime ?? DateTime.utc().toMillis();
            maxTime = data.properties.maxTime ?? DateTime.utc().toMillis();
        }

        let minDataReceived = DateTime.fromMillis(minTime, { zone: 'utc' })
            .set({ second: 0, millisecond: 0 });
        let maxDataReceived = DateTime.fromMillis(maxTime, { zone: 'utc' })
            .set({ second: 0, millisecond: 0 });

        let dataBuffer = {
            id: 'tracks',
            start: minDataReceived.toLocal().toRFC2822(),
            end: maxDataReceived.toLocal().toRFC2822(),
            content: 'Tracks',
            type: 'background',
            style: 'background-color: rgba(236,105,7,.5); color: white; font-size: 20px;'
        };

        return {
            dataBuffer: dataBuffer,
            maxDataReceived: maxDataReceived
        };
    }

    static getIcon(feature) {
        let color = feature.properties.vesselColor;

        if (feature.properties.isProjectVessel)
            color = '#00008B';
        if (feature.properties.featureId.length == 7 | feature.properties.featureId.substring(0,2) == 99) {
            return L.divIcon({
                html:
                    `<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                    <rect width="10" height="10" x="0" y="0" style="fill:rgb(255,255,0);stroke-width:1;stroke:black" />
                </svg>`,
                className: "rotated-marker",
                style: "transform-origin: center;",
                iconSize: [10, 10], // size of the icon
                //shadowSize:   [50, 64], // size of the shadow
                iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
                //shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
            });
        } else {

            return L.divIcon({
                html:
                    `<svg version="1.1" x="0px" y="0px" viewBox="0.419 0.208 12.867 28.316" style="enable-background:new 0 0 595.3 841.9;" xmlns="http://www.w3.org/2000/svg">
                    <g transform="matrix(0.09179, 0, 0, 0.081918, -4.862583, -12.040033)" style="">
                    <path style="fill:${color};stroke:#000000;stroke-width:15;" d="M183.7,319.9c-10.9-69.5-33.7-124.2-52.6-160.8c-1.5-2.9-5.7-2.9-7.2,0c-18.8,36.8-41.4,91.4-52.3,160.8 C61,387.7,65.3,445.3,71.5,485c0.2,1.5,1.5,2.6,3,2.6c35.4,0.1,70.8,0.1,106.3,0.2c1.5,0,2.8-1.1,3-2.6 C190,445.5,194.4,387.9,183.7,319.9z"/>
                    </g>
                </svg>
            `,
                //shadowUrl: 'leaf-shadow.png',
                className: "rotated-marker",
                style: "transform-origin: center;",
                iconSize: [15, 30], // size of the icon
                //shadowSize:   [50, 64], // size of the shadow
                iconAnchor: [7, 18], // point of the icon which will correspond to marker's location
                //shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
            });
        }
    }

    static getPopup(feature) {

    }

    static getTooltip(feature) {

    }

    static getHotline(feature) {
    }

}